import { Datagrid, DateField, DateInput, FilterButton, FunctionField, List, ListContextProvider, SelectField, SelectInput, TextField, TextInput, TopToolbar, UrlField, useList, useRecordContext } from "react-admin";
import { SmppStatuses } from "../../models/smppStatuses";
import * as XLSX from 'xlsx';
import { LostRapportoShow } from "./lostRapportoShow";

const postFilters = [
    <TextInput source='Code.$contains' resettable />,
    <TextInput source='Phone.$contains' resettable />,
    <DateInput source="CreateDate.$ge" label="Create Date From" />,
    <DateInput source="CreateDate.$le" label="Create Date To" />,
    <DateInput source="LastRequestDate.$contains" label="Overfull" />,
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

export const LostRapportoList = () =>{
    return (
        <List filters={postFilters} exporter={false} title='Lost rapporto list'  actions={<ListActions />}>
            <Datagrid expand={<LostRapportoShow/>} rowClick='expand' expandSingle>
                <TextField source='Code' />
                <TextField source='Phone' />
                <DateField source='CreatedDate' showTime/>
                <TextField source='OverFull' />
            </Datagrid>
        </List>
    );
}